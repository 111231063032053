import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { FAQS } from "@/constant/copyrights";
import Link from "next/link";

export default function Faq() {
	return (
		<>
			<div className="mt-[90px] flex flex-col items-center justify-center gap-4 max-md:text-center">
				<h3 className="text-base font-[600] text-[#063b89]">FAQs</h3>
				<h1 className="text-2xl font-bold text-black">
					Pergunte-nos qualquer coisa!
				</h1>
				<p className="text-base text-foreground/60">
					Precisa de algo esclarecido? Aqui estão nossas perguntas mais
					frequentes.
				</p>
			</div>
			<Accordion type="single" collapsible className="w-full">
				{
					FAQS.map((faq, index) => (
						<AccordionItem value={faq.answer} key={index}>
							<AccordionTrigger className="text-start">{faq.question}</AccordionTrigger>
							<AccordionContent>
								{faq.answer}
							</AccordionContent>
						</AccordionItem>
					))
				}
			</Accordion>
			<div className="container mt-[60px] grid max-md:grid-cols-1 items-center justify-between gap-4 md:grid-cols-2 md:grid-rows-2">
				<div>
					<h3 className="text-xl font-bold">Ainda tem dúvidas?</h3>
					<p className="text-foreground/60">
						Não encontrou a resposta que procura? Converse com nossa equipe
						amigável.
					</p>
				</div>
				<div className="flex justify-end ">
					<Link href={"/lawyer/contact"} className="max-md:w-full">
						<Button className="flex-2 flex max-md:w-full">Entre em contato</Button>
					</Link>
				</div>
			</div>
		</>
	);
}
