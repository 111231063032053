import {
  Ban,
  CirclePlus,
  CreditCard,
  Heart,
  Mail,
  RefreshCw,
} from 'lucide-react'

export const FAQS = [
  {
    icon: Heart,
    question: '1.	O que é o MINB?',
    answer: `O MINB é a plataforma que reúne todos os imóveis em um só lugar e que ajuda corretores e imobiliárias de todo o Brasil a impulsionarem suas vendas. 
O MINB é a plataforma que reúne todos os imóveis em um só lugar o que ajuda as imobiliárias e corretores de todo o Brasil a impulsionarem suas vendas.
A MINB é uma ferramenta que as imobiliárias utilizam para acelerar o encontro entre clientes e seus imóveis aqui anunciados.
`,
  },
  {
    icon: RefreshCw,
    question: '2.	O MINB vende imóveis?',
    answer: `NÃO, O MINB não é uma imobiliária. Somos um portal de alta visibilidade que impulsiona as vendas das imobiliárias e corretores.`,
  },
  {
    icon: Ban,
    question: '3.	O MINB participa de alguma negociação?',
    answer: `Não, o MINB direciona potenciais clientes diretamente às imobiliárias, corretores e proprietários, e não realiza nenhuma negociação. 
O MINB não recebe nada da negociação entre cliente e imobiliária.
O MINB não participa e não interfere na negociação entre cliente e imobiliária.
`,
  },
  {
    icon: CirclePlus,
    question: '4.	O MINB é seguro?',
    answer: `SIM totalmente seguro, pois o cliente ao selecionar o imóvel desejado, é automaticamente direcionado ao anunciante. Seja ele imobiliária, corretor ou proprietário.`,
  },
  {
    icon: CreditCard,
    question: '5.	Quem pode anunciar no MINB?',
    answer: `Imobiliárias, corretores independentes e proprietários de imóveis, todos denominamos de anunciantes. 
É importante ressaltar a extrema segurança proporcionada por um profissional (Imobiliária ou corretor) devidamente registrado no CRECI, ao conduzir a negociação de qualquer imóvel.
`,
  },
  {
    icon: Mail,
    question:
      '6.	O MINB é responsável pelas negociações entre Anunciantes e Clientes?',
    answer: `NÃO, a plataforma do MINB apenas expõe os imóveis dos anunciantes exatamente como estão na origem. Onde o cliente será direcionado automaticamente para o contato com o anunciante.`,
  },
  {
    icon: Mail,
    question:
      '7.	Consigo comprar, vender ou alugar um imóvel diretamente com o MINB?',
    answer: `NÃO, é impossível, não somos corretores de imóveis. Não existe esse canal de acesso entre clientes e o MINB.`,
  },
  {
    icon: Mail,
    question: `8.	Como conseguir abaixar o preço de um imóvel anunciado no MINB?`,
    answer: `Diretamente com o anunciante, não alteramos nenhuma informação ou dados dos imóveis dos anunciantes. Os anúncios são copias fiéis de como estão anunciados na origem.`,
  },

  {
    icon: Mail,
    question: `9.	O MINB é gratuito? / Anunciar no MINB é gratuito?`,
    answer: `Sim, o MINB é uma plataforma 100% gratuita cujo objetivo principal é ajudar a impulsionar as vendas.
Sim, o MINB é uma plataforma 100% gratuita cujo objetivo é facilitar e acelerar o encontro entre clientes e Imobiliárias e corretores.
`,
  },

  {
    icon: Mail,
    question: `10.	Como cadastrar?`,
    answer: `Crie sua conta clicando em "Login" e, em seguida, em "Cadastrar". Preencha seus dados, selecione o tipo de conta e pronto! Você já pode publicar a quantidade de imóveis que desejar. `,
  },

  {
    icon: Mail,
    question: `11.	Saiba como funciona o "ENCOMENDAR`,
    answer: `Caso você procure por todo o site e não encontre o imóvel desejado, pode encomendar um imóvel com as características desejadas. Assim, quando um imóvel com as mesmas características que você especificou for publicado, você receberá um e-mail.`,
  },
  {
    icon: Mail,
    question: `12.	Utilizar a ferramenta "ENCOMENDAR" como investimento?`,
    answer: `Os investidores estão usando essa ferramenta como um “Lance”. Eles cadastram os dados dos imóveis desejados e anunciam o valor disposto a pagar. Ele receberá um e-mail do MINB quando:
A.	Um imóvel com a mesma descrição for anunciado.
B.	O proprietário decidir por aceitar a proposta e reduzir o valor.
`,
  },
  {
    icon: Mail,
    question: `13.	Corretores, tenham um site gratuito / SITE GRATUITO no MINB para corretores`,
    answer: `Para os corretores que desejam ter um site gratuito para apresentar seus imóveis a milhares de potenciais clientes, o MINB oferece uma página exclusiva onde todos os seus imóveis estarão disponíveis.`,
  },
  {
    icon: Mail,
    question: `14.	Quem pode ter um SITE GRATUITO no MINB?`,
    answer: `Imobiliárias, corretores independentes e proprietários de imóveis, todos denominamos de anunciantes. `,
  },
  {
    icon: Mail,
    question: `15.	Agilidade para imobiliárias`,
    answer: `Você, empresário do setor imobiliário, no MINB seus imóveis estarão disponíveis de forma prática e gratuita para todo o Brasil, ajudando a alavancar suas vendas.`,
  },
  {
    icon: Mail,
    question: `16.	Porque utilizar o MINB?`,
    answer: `Chega de ficar horas preenchendo a mesma procura em vários sites diferentes. No Aqui no MINB você preenche os dados ou filtro do que deseja uma única vez e o MINB procura todos os imóveis de todas as imobiliárias e anúncios independentes.
Economize tempo: faça o filtro uma única vez e a MINB busca todos os imóveis de várias fontes para você.
1.	O seu imóvel da sua imobiliária favorita está aqui.
1.	A SINB foi criada baseada na filosofia de grandes investidores, assim nós conectamos proprietários, construtores, investidores e empresas.
.`,
  },
]

export const TERMS = [
  {
    title: '1. Uso do Site',
    body: 'O MINB.com.br fornece informações sobre imóveis para locação e aluguel. Os usuários concordam em utilizar o site de maneira legal e em conformidade com todas as leis aplicáveis.',
  },
  {
    title: '2. Registro de Conta',
    body: 'Alguns recursos do site podem exigir que os usuários se registrem para obter uma conta. Os usuários são responsáveis por manter suas informações de conta atualizadas e seguras.',
  },
  {
    title: '3. Conteúdo do Usuário',
    body: 'Os usuários concordam em não publicar, transmitir ou compartilhar conteúdo ofensivo, difamatório, ilegal ou prejudicial. O MINB.com.br se reserva o direito de remover qualquer conteúdo inadequado.',
  },
  {
    title: '4. Propriedade Intelectual',
    body: 'O conteúdo, logotipos e design do MINB.com.br são protegidos por direitos autorais e outras leis de propriedade intelectual. Os usuários concordam em não copiar, reproduzir ou distribuir esse conteúdo sem permissão.',
  },
  {
    title: '5. Responsabilidade',
    body: 'O MINB.com.br não se responsabiliza por informações imprecisas ou incompletas em listagens de propriedades. Os usuários devem verificar todas as informações diretamente com os anunciantes.',
  },
  {
    title: '6. Alterações nos Termos',
    body: 'Reservamos o direito de fazer alterações nestes Termos a qualquer momento. As alterações serão publicadas no site com uma data de revisão. O uso continuado do site após alterações constitui a aceitação dos novos Termos.',
  },
  {
    title: '7. Encerramento de Conta',
    body: 'Reservamos o direito de encerrar contas de usuários que violem estes Termos.',
  },
  {
    title: '8. Lei Aplicável',
    body: `Estes Termos são regidos pelas leis do Brasil. Qualquer disputa relacionada a estes Termos será resolvida nos tribunais competentes no Brasil. Ao utilizar o MINB.com.br, os usuários concordam em cumprir estes Termos e a Política de Privacidade associada. Se você não concorda com esses termos, por favor, não use o site. Se você tiver alguma dúvida ou preocupação, entre em contato conosco em Inserir informações de contato`,
  },
]

export const POLICY_PRIVACY = [
  { 
    title: '1. Informações Coletadas',
    body: 'Coletamos informações pessoais de nossos usuários, como nome, endereço de e-mail, número de telefone e detalhes relacionados a propriedades de aluguel ou locação. Essas informações são fornecidas voluntariamente pelos usuários ao utilizar nosso site, preencher formulários de contato ou se comunicar conosco.',
  },
  { 
    title: '2. Uso das Informações',
    body: 'Utilizamos as informações coletadas para: – Fornecer serviços personalizados, como pesquisas de imóveis e recomendações. – Facilitar a comunicação entre usuários e anunciantes de propriedades. – Melhorar e personalizar a experiência do usuário no site. – Envio de comunicações relacionadas a serviços, atualizações ou ofertas. – Cumprir obrigações legais e regulatórias.',
  },
  { 
    title: '3. Compartilhamento de Informações',
    body: 'Não vendemos, alugamos ou compartilhamos informações pessoais com terceiros, a menos que seja necessário para fornecer serviços específicos ou atender a requisitos legais.',
  },
  { 
    title: '4. Segurança das Informações',
    body: 'Adotamos medidas de segurança para proteger as informações dos usuários, incluindo criptografia de dados e acesso restrito a informações pessoais. No entanto, a segurança de informações transmitidas pela internet não pode ser garantida.',
  },
  { 
    title: '5. Cookies e Tecnologias Semelhantes',
    body: 'Utilizamos cookies e tecnologias similares para melhorar a experiência do usuário, analisar o uso do site e fornecer publicidade direcionada. Os usuários podem gerenciar as configurações de cookies em seus navegadores.',
  },
  { 
    title: '6. Links para Sites de Terceiros',
    body: 'Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelas práticas de privacidade ou conteúdo desses sites. Recomendamos que os usuários revisem as políticas de privacidade desses sites antes de fornecer qualquer informação pessoal.',
  },
  { 
    title: '7. Menores de Idade',
    body: 'Nosso site não é direcionado a menores de 18 anos. Não coletamos intencionalmente informações de menores de idade. Se tivermos conhecimento de que coletamos informações pessoais de menores, tomaremos medidas para excluí-las.',
  },
  { 
    title: '8. Alterações na Política de Privacidade',
    body: 'Reservamos o direito de fazer alterações nesta Política de Privacidade a qualquer momento. As alterações serão publicadas no site com uma data de revisão. É responsabilidade dos usuários revisar periodicamente esta Política.',
  }
]

export const COUNTRIES = [
  { name: 'Brazil', code: 'BR', dial_code: '+55' },
  { name: 'Canada', code: 'CA', dial_code: '+1' },
  { name: 'United Kingdom', code: 'GB', dial_code: '+44' },
  { name: 'Australia', code: 'AU', dial_code: '+61' },
  { name: 'Germany', code: 'DE', dial_code: '+49' },
  { name: 'France', code: 'FR', dial_code: '+33' },
  { name: 'India', code: 'IN', dial_code: '+91' },
  { name: 'Japan', code: 'JP', dial_code: '+81' },
  { name: 'China', code: 'CN', dial_code: '+86' },
  { name: 'South Africa', code: 'ZA', dial_code: '+27' },
  { name: 'Mexico', code: 'MX', dial_code: '+52' },
  { name: 'Argentina', code: 'AR', dial_code: '+54' },
  { name: 'Italy', code: 'IT', dial_code: '+39' },
  { name: 'Spain', code: 'ES', dial_code: '+34' },
]
