'use client'
import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules'

import { Swiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}
export const InfiniteMovingCards = ({ children }: Props) => {
  return (
    <Swiper
      className="w-full"
      modules={[Navigation, Pagination, A11y, Autoplay]}
      spaceBetween={30}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      pagination={{ clickable: true, el: '.swiper-pagination' }}
      breakpoints={{
        640: { slidesPerView: 1, spaceBetween: 20 },
        768: { slidesPerView: 2, spaceBetween: 25 },
        1024: { slidesPerView: 3, spaceBetween: 30 },
        1200: { slidesPerView: 4, spaceBetween: 30 },
      }}
    
    >
      {children}
    </Swiper>
  )
}
