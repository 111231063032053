import { Button } from "@/components/ui/button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Loader2, MapPin, Search, SlidersHorizontal } from "lucide-react"
import { useFilter } from "./filter.modal"
import { Input } from "@/components/ui/input"
import Link from "next/link"

type Props = ReturnType<typeof useFilter>
export const FilterView = ({inputAutoComplete, handleOpen, form }:Props) => {
  return    <div className="-mt-14 max-lg:-mt-36 flex w-full justify-center max-sm:mb-14">
  <Form {...form}>
    <div className="max-lg:bg-red z-10 flex w-[80%] items-end justify-between gap-2 rounded-[12px] border border-[#000000]/20 bg-white p-6 max-xl:w-full max-lg:flex-col max-sm:flex-col">
      <div className="grid w-full flex-1 grid-cols-2 items-end justify-center gap-x-10 max-xl:grid-cols-2 max-md:grid-cols-1 max-sm:flex max-sm:w-full max-sm:flex-col lg:flex lg:grid-cols-6">
        <div className="h-full w-[.8px] bg-black/10 max-2xl:sr-only" />
        <FormField
          control={form.control}
          name="location"
          render={() => (
            <FormItem className="min-h:[450px] col-span-1 w-full max-xl:col-span-2 max-md:col-span-1">
              <FormLabel className="text-base text-[#3c4453]">Endereço</FormLabel>
              <FormControl className="w-full">
                <div className="flex w-full items-center gap-2 rounded-md border border-[#d0d5dd] border-input px-3 py-1 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                  <Input
                    ref={inputAutoComplete}
                    placeholder="Localização"
                    className="w-full border-none px-0 py-0 text-base"
                  />
                  <MapPin size={24} />
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex flex-wrap items-center gap-4 max-sm:w-full">
        <Button className="w-[12.5rem] gap-2 p-6 max-sm:flex-1">
          {form.formState.isSubmitting && <Loader2 className="animate-spin" />}
          {!form.formState.isSubmitting && (
            <Link href={"/search"} className="flex gap-2 p-6 max-sm:flex- justify-center">
              <Search size={20} />
              MOSTRAR LISTA
            </Link>
          )}
        </Button>
        <Button
          variant={"outline"}
          onClick={handleOpen}
          className="border-[#000000]/15 gap-2 p-6 max-sm:flex-1"
        >
          <SlidersHorizontal size={20} />
          BUSCAR NO MAPA
        </Button>
      </div>
    </div>
  </Form>
</div>
}