import Image from "next/image"
import { useHome } from "./home.model"
import { FILTER_HOME_IMOVEIS } from "@/constant/filters"
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Property } from "@/@types/global"
import { InfiniteMovingCards } from "@/components/ui/infinite-moving-cards"
import { SwiperSlide } from "swiper/react"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import dynamic from "next/dynamic"
import { MousePointerClick } from "lucide-react"
import Faq from "./_component/faq"
import CardGridView from "../_components/cards/views/card-grid.view"
import { InfiniteScrollView } from "../_components/infinite-scroll/infinite-scroll.view"
import { getPropertiesInfiniteScroll } from "@/services/property"
import { useInView } from "react-intersection-observer"
import { Fragment, useEffect } from "react"
import { FilterView } from "@/app/(main)/_components/filter/filter.view"
import { useFilter } from "@/app/(main)/_components/filter/filter.modal"


type HomeProps = ReturnType<typeof useHome>

const Maps = dynamic(
  () => {
      return import('../../../components/globals/maps/views/google-map.view')
  },
  { ssr: false },
)
export const HomeView = ({ handleSelectChange, mapInput,  propertiesCloseToMe, propertiesVisible, refCloseMap, setZoom,  zoom, propertiesScroll }: HomeProps) => {
  const methods = useFilter({inputAutoComplete: mapInput})
  const { fetchNextPage, data  } = getPropertiesInfiniteScroll({ zoom: 12, maps: true })
  const { ref, inView } = useInView();
  useEffect(() => {
		if (inView) {
			fetchNextPage();
		}
	}, [inView, fetchNextPage]);
  return (
    <>
      <section>
        <div className="relative h-[650px] w-full">
          <div
            className="bg-red-150 absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center backdrop-blur-sm"
            ref={refCloseMap}
          >
            <div
              className="inline-flex cursor-pointer gap-4 rounded-lg bg-white px-6 py-3 hover:brightness-[1.15]"
              onClick={() => refCloseMap.current?.classList.add('hidden')}
            >
              <MousePointerClick />
              <p className="text-base text-[#3c4453]">
                Clique para abrir o mapa
              </p>
            </div>
          </div>
          <Maps zoom={zoom} setZoom={setZoom} setQuantity={()=> 0} />
        </div>
        <FilterView {...methods} />
      </section>
      <section
        className="mx-auto mt-[50px] max-w-[1500px]"
        id="property-listing"
      >
        <div className="flex max-md:flex-col gap-4 w-full max-md:p-2 flex-wrap items-center justify-between">
          <h1 className="text-2xl font-[400] max-md:text-center">
            Confira os <strong className="italic">Imóveis destaque</strong>
          </h1>
          <div className="flex items-center justify-between gap-[0.625rem] sm:w-2/5">
            {FILTER_HOME_IMOVEIS.map((value, key) => {
              
              return (
                <Select
                  key={value.label}
                  onValueChange={(v) => {
                    handleSelectChange(value.key, v)
                  }}
                >
                  <SelectTrigger className="flex h-11 w-full items-center gap-2 rounded-md border border-[#d0d5dd] px-3 py-0.5 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
                    <SelectValue placeholder={value.label} />
                  </SelectTrigger>
                  <SelectContent className="outline-none focus:outline-none">
                    <SelectGroup>
                      <SelectLabel>{value.label}</SelectLabel>
                      {value.values.map((v) => (
                        <SelectItem key={v} value={v}>
                          {v}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )
            })}
          </div>
        </div>
        <div className="mt-3 flex flex-col max-md:p-2 flex-wrap justify-between gap-4 p-4 max-md:gap-2 sm:flex-row">
        <InfiniteScrollView key={"sda1s564da564w5a"}>
        {data?.pages.map((page, pageIndex) => (
          <Fragment key={`page-${pageIndex}`}>
            {page?.data?.map((card: Property, index: number) => (
              <SwiperSlide key={`card-${card.id}`}>
                {index === page?.data?.length - 1 && (
                  <div ref={ref} key={`ref-${card.id}`} />
                )}
                <CardGridView data={card} />
              </SwiperSlide>
            ))}
          </Fragment>
        ))}
      </InfiniteScrollView>
        </div>
      </section>
      {/* Properties seen by you */}
      {propertiesCloseToMe.data?.length > 0 && propertiesCloseToMe?.data && (
        <section className="mx-auto mt-[50px] max-w-[1420px]">
          <div className="flex w-full flex-col flex-wrap justify-between gap-10">
            <h1 className="text-2xl font-[400] max-md:text-center">
              Imóveis <strong className="italic">próximos à você</strong>{' '}
            </h1>
            <InfiniteMovingCards>
              {propertiesCloseToMe?.data?.map((i: Property, idx: number) => (
                <SwiperSlide>
                  <CardGridView data={i} key={i.id} />
                </SwiperSlide>
              ))}
            </InfiniteMovingCards>
          </div>
        </section>
      )}
      {/* Last properties seen by you */}
      {propertiesVisible.length > 0 && propertiesVisible && (
        <section className="mx-auto mt-[50px] max-w-[1420px] p-4">
          <div className="flex w-full flex-wrap justify-between gap-10">
            <div className="flex w-full flex-wrap items-center justify-between max-sm:flex-col max-sm:gap-2 max-md:text-center">
              <h1 className="text-2xl font-[400] max-md:text-center w-full">
                Últimos imóveis{' '}
                <strong className="italic">visto por você</strong>
              </h1>
            </div>
            {/* <InfiniteMovingCards>
              {propertiesVisible &&
                propertiesVisible?.map((i: Property) => (
                  <SwiperSlide key={i.id}>
                    <CardGridView data={i} compared />
                  </SwiperSlide>
                ))}
            </InfiniteMovingCards> */}
          </div>
        </section>
      )}
      {/* All properties in */}
      <section className="mt-10 flex flex-col items-center justify-center">
        <div>
          <h1 className="relative max-md:text-xl inline-flex text-wrap text-center text-3xl font-[400]">
            Todos os imóveis em{' '}
            <strong className="italic ml-4"> um só lugar</strong>
            <Image
              className="absolute md:-bottom-1 max-md:-bottom-1 md:w-[200px] max-md:mt-10 right-0"
              alt=""
              src={'/ondula.svg'}
              width={120}
              height={9}
            />
          </h1>
        </div>
        <div className=" flex items-center relative max-md:flex-col">
          <div className="flex max-md:mt-4">
            <Image 
              alt="Descrição da imagem" 
              src="/homecelular.png" 
              width={1200} 
              height={800} 
              className="w-full max-w-[90%] md:max-w-[70%]" 
            />
          </div>

          <div className="inline-flex flex-wrap gap-4 max-md:justify-center">
            <div className="max-sm:w-full max-sm:p-6 sm:max-w-[400px]">
              <h6 className="text-lg font-semibold">Aumente suas vendas</h6>
              <p className="text-justify text-sm text-[#7B818F]">
                Anuncie seu imóvel de forma gratuita e potencialize suas vendas.
              </p>
            </div>
            <div className="max-sm:w-full max-sm:p-6 sm:max-w-[400px]">
              <h6 className="text-lg font-semibold">Visibilidade garantida</h6>
              <p className="text-justify text-sm text-[#7B818F]">
                Explore milhões de imóveis em nosso mapa interativo.
              </p>
            </div>
            <div className="max-sm:w-full max-sm:p-6 sm:max-w-[400px]">
              <h6 className="text-lg font-semibold">
                Impulsione suas vendas com o MINB
              </h6>
              <p className="text-justify text-sm text-[#7B818F]">
                Utilize nossas ferramentas para maximizar suas oportunidades de
                venda.
              </p>
            </div>
            <div className="max-sm:w-full max-sm:p-6 sm:max-w-[400px]">
              <h6 className="text-lg font-semibold">
                Pesquise com facilidade e praticidade
              </h6>
              <p className="text-justify text-sm text-[#7B818F]">
                Tenha acesso a um painel exclusivo para imobiliárias e
                corretores, e mantenha-se atualizado sobre o mercado
                imobiliário.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Questions */}
      <section className="container">
        <Faq />
      </section>
    </>
  )
}